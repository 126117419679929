.text-animate {
    display: inline-block;
    opacity: 0;
    -webkit-animation: bounceIn;
    animation: bounceIn;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    animation-delay: 1s;
    -webkit-animation-delay: 1s;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    min-width: 10px;
}

.text-animate-hover {
    min-width: 10px;
    display: inline-block;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;

    &:hover {
        animation: rubberBand 1s;
        color: #ffd700;
    }
}

@for $i from 1 through 35 {
    .text-animate._#{$i} {
        -webkit-animation-delay: #{$i / 10}s;
        animation-delay: #{$i / 10}s;
    }
}

@media screen and (max-width: 700px) {
    .text-animate {
        animation: none;
        animation-duration: none;
        animation-delay: none;
        animation-fill-mode: none;
        opacity: 1;
    }

    @for $i from 1 through 35 {
        .text-animate._#{$i} {
            animation-delay: none;
        }
    }
}

